<template>
    <div class="content">
        <div>
            <el-button type="primary" class="el-icon-plus" @click="Add"> 新增</el-button>
        </div>

        <el-table :data='ApplyDataList' border style="width: 100%;font-size:13px" max-height='calc(100vh - 180px)' height='calc(100vh - 180px)' stripe :highlight-current-row='true' size="mini">
            <el-table-column align="center" prop="apply_build_name" label="工程名称"></el-table-column>
            <el-table-column align="center" prop="build_name" label="工程地点"></el-table-column>
            <el-table-column align="center" prop="apply_order_no" label="申请编号"></el-table-column>
            <el-table-column align="center" prop="user_name" label="申请人"></el-table-column>
            <el-table-column align="center" prop="apply_time" label="申请时间">
                <template slot-scope="scope">
                    {{FormData(scope.row.apply_time)}}
                  </template>
                </el-table-column>
            </el-table-column>
            <el-table-column align="center" prop="" label="审核结果">
                <template slot-scope="scope">
                    <label :style="{color:(scope.row.apply_pd_state===0||scope.row.apply_fb_state===0||scope.row.apply_ps_state===0||scope.row.apply_pb_state===0||scope.row.apply_lc_state===0||scope.row.apply_dt_state===0?'red':'green')}"> {{CheckApplyResult(scope.row,1)}}</label>
                   
                  </template>
                </el-table-column>
            </el-table-column>
            <el-table-column align="center" prop="" label="审核意见">
                <template slot-scope="scope">
                    {{CheckApplyResult(scope.row,2)}}
                  </template>
                </el-table-column>
            </el-table-column>
            <el-table-column align="center" label="操作">
                <template slot-scope="scope">
                    <el-link type="primary" @click="Edit(scope.row)">编辑</el-link>
                    <el-link type="danger" @click="Del(scope.row)">删除</el-link>
                </template>
            </el-table-column>
        </el-table>
        <el-pagination style="width:100%" layout="sizes,prev, pager, next" :page-sizes="[15, 30, 45, 60]" @current-change="current_change" :total="searchForm.total" background :page-size="searchForm.pageSize" @size-change="handleSizeChange"></el-pagination>


        <el-dialog v-if="applyDialog" :title="title" :visible.sync="applyDialog" width='900px' top="5vh" :append-to-body='true'>
            <el-form :model="applyForm" :rules="rules" ref="applyForm" label-width="150px" size='medium' :inline="true">
                <el-row :gutter="20">
                    <el-col :span="12">
                        <el-form-item label="工程名称" prop="apply_build_name">
                            <el-input v-model="applyForm.apply_build_name" style="width: 250px;" :disabled="User.user_type!==1"></el-input>
                       </el-form-item>
                       <el-form-item label="业主单位">
                            <el-input v-model="applyForm.apply_owner_unit" style="width: 250px;" :disabled="User.user_type!==1"></el-input>
                       </el-form-item>
                       <el-form-item label="运输单位">
                            <el-select v-model="applyForm.apply_company_id" style="width: 250px;" :disabled="User.user_type!==1">
                                <el-option v-for="item in $store.state.CompanyDataList" :label="item.cp_name" :value="item.cp_id" :key="item.cp_id" ></el-option>
                                <el-option label=" " :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="签订运输合同">
                            <el-radio-group v-model="applyForm.apply_sign_contract" :disabled="User.user_type!==1">
                                <el-radio :label="0">否</el-radio>
                                <el-radio :label="1">是</el-radio>
                              </el-radio-group>
                        </el-form-item>
                        <el-form-item label="外运或内转">
                            <el-radio-group v-model="applyForm.apply_inout" :disabled="User.user_type!==1">
                                <el-radio :label="0">外运</el-radio>
                                <el-radio :label="1">内转</el-radio>
                            </el-radio-group>
                        </el-form-item>
                        <el-form-item label="运输时限">
                            <el-date-picker v-model="applyForm.apply_limit_day" type="daterange" range-separator="至" start-placeholder="开始日期" end-placeholder="结束日期" value-format="yyyy-MM-dd" format="yyyy-MM-dd" style="width: 250px;" :disabled="User.user_type!==1"></el-date-picker>
                        </el-form-item>
                    </el-col>
                    <el-col :span="12">
                        <el-form-item label="工程地点" >
                            <el-select v-model="applyForm.apply_building_id"  style="width:250px" :disabled="User.user_type!==1">
                                <el-option v-for="item in CheckLocation(1)" :label="item.lt_name" :value="item.lt_id" :key="item.lt_id" ></el-option>
                                <el-option label=" " :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="施工单位">
                            <el-input v-model="applyForm.apply_build_unit" style="width:250px" :disabled="User.user_type!==1"></el-input>
                        </el-form-item>
                        <el-form-item label="废弃土方量(万方)">
                            <el-input-number v-model="applyForm.apply_weight" :precision="3" :step="1" :min="0" style="width:250px" :disabled="User.user_type!==1"></el-input-number>
                        </el-form-item>
                        <el-form-item label="拟运输路线">
                            <el-select v-model="applyForm.apply_route_id" style="width:250px" :disabled="User.user_type!==1">
                                <el-option v-for="item in routeDatalist" :label="item.rt_name" :value="item.rt_id" :key="item.rt_id" ></el-option>
                                <el-option label=" " :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="申请倾倒处置地点">
                            <el-select v-model="applyForm.apply_dump_id" style="width:250px" :disabled="User.user_type!==1">
                                <el-option v-for="item in CheckLocation(0)" :label="item.lt_name" :value="item.lt_id" :key="item.lt_id" ></el-option>
                                <el-option label=" " :value="0"></el-option>
                            </el-select>
                        </el-form-item>
                        <el-form-item label="运输时间">
                            <el-time-picker is-range v-model="applyForm.apply_limit_time" range-separator="至" start-placeholder="开始时间" end-placeholder="结束时间" placeholder="选择时间范围" value-format="HH:mm" format="HH:mm" style="width: 250px;" :disabled="User.user_type!==1"></el-time-picker>
                        </el-form-item>
                    </el-col>
                </el-row>
                <el-row :gutter="20" v-if="User.user_type>1">
                    <el-col :span="4" class="span">
                        <div class="a" >会签部门</div>
                        <span class="b">{{User.user_type===2?'规自局测绘':(User.user_type===3?'鑫诚公司':(User.user_type===4?'交警中队':(User.user_type===5?'交警大队':(User.user_type===6?'分管领导':'局长'))))}}</span>
                    </el-col>
                    <el-col :span="6" class="span">
                        <span class="a">会签意见</span>
                        <el-radio-group class="b" v-model="applyForm.apply_state">
                            <el-radio :label="0">不通过</el-radio>
                            <el-radio :label="1">通过</el-radio>
                        </el-radio-group>
                    </el-col>
                    <el-col :span="14" class="span">
                        <span class="a">备注</span>
                        <div style="margin-top: 30px;display: flex;flex-direction: row;justify-content: center;">
                            <el-input  type="textarea" :rows="8" placeholder="审核不通过原因" v-model="applyForm.apply_remark" style="margin-right: 5px;"></el-input>
                            <el-upload  v-if="User.user_type===3||User.user_type===4"  action="#"  list-type="picture-card"  :on-preview="handlePictureCardPreview" :on-remove="handleRemove"  :auto-upload="false" :on-change="handleChange" :class="objClass" :file-list="fileList" :limit="1">
                                <i class="el-icon-plus"></i>
                            </el-upload>
                            <el-dialog :visible.sync="dialogVisible" :append-to-body="true">
                                <img width="100%" :src="dialogImageUrl"  alt="">
                            </el-dialog>
                        </div>
                       

                    </el-col>
                </el-row>
            </el-form>
            <span slot="footer" class="dialog-footer">
                <el-button @click="applyDialog = false">取 消</el-button>
                <el-button type="primary" @click="submit">确 定</el-button>
            </span>
        </el-dialog>
    </div>
</template>

<script>
import {post,get,FormateDate} from '../../util/util'

export default{
    data(){
        return{
            User:null,

            applyDialog:false,
            title:'',
            routeDatalist:[],
            ApplyDataList:[],
            
            searchForm:{
                pageNo:1,
                pageSize:15,
                total:0
            },

            applyForm:{
                apply_id:0,
                apply_build_name:'',
                apply_building_id:0,
                apply_owner_unit:'',
                apply_build_unit:'',
                apply_company_id:0,
                apply_weight:0,
                apply_route_id:0,
                apply_sign_contract:0,
                apply_inout:0,
                apply_dump_id:0,
                apply_limit_time:'',
                apply_limit_day:'',
                apply_route_id:0,

                apply_state:0,
                apply_remark:'',
                imgData:''
            },
            rules: {
                apply_build_name: [{ required: true, message: '请输入工程名称', trigger: 'blur' }],
                apply_owner_unit:[{ required: true, message: '请输入业主单位', trigger: 'blur' }],
                apply_build_unit:[{ required: true, message: '请输入施工单位', trigger: 'blur' }],

            },

            dialogImageUrl: '',
			dialogVisible: false,
			fileList: [],
			objClass: {
				upLoadShow: true,
				upLoadHide: false,
			},

        }
    },
    computed: {
        CheckLocation () {
            return function (type) {
                var list=this.$store.state.LocationDataList
                if(type===1){
                    return list.filter(a=>a.lt_type===1)
                }
                else{
                    return list.filter(a=>a.lt_type===0)
                }
            }
        },
        FormData () {
            return function (time) {
                if(time)
                return FormateDate(time, 2)
            }
        },
        CheckApplyResult(){
            return function(row,type){
                let result='审核通过'
                let remark=''
                if(row.apply_pd_state===null){
                    result="等待规自局审核"
                }
                else{
                    if(row.apply_pd_state===0){
                        result="规自局审核不通过"
                        remark=row.apply_pd_remark
                    }
                    else{
                        if(row.apply_fb_state===null){
                            result="等待鑫诚公司审核"
                        }
                        else{
                            if(row.apply_fb_state===0){
                                result="鑫诚公司审核不通过"
                                remark=row.apply_fb_remark
                            }
                            else{
                                if(row.apply_ps_state===null){
                                    result="等待中队审核"
                                }
                                else{
                                    if(row.apply_ps_state===0){
                                        result="中队审核不通过"
                                        remark=row.apply_ps_remark
                                    }
                                    else{
                                        if(row.apply_pb_state===null){
                                            result="等待大队审核"
                                        }
                                        else{
                                            if(row.apply_pb_state===0){
                                                result="大队审核不通过"
                                                remark=row.apply_pb_remark
                                            }
                                            else{
                                                if(row.apply_lc_state===null){
                                                    result="等待分管领导审核"
                                                }
                                                else{
                                                    if(row.apply_lc_state===0){
                                                        result="分管领导审核不通过"
                                                        remark=row.apply_lc_remark
                                                    }
                                                    else{
                                                        if(row.apply_dt_state===null){
                                                            result="等待局长审审核"
                                                        }
                                                        else{
                                                            if(row.apply_dt_state===0){
                                                                result="局长审审核不通过"
                                                                remark=row.apply_dt_remark
                                                            }
                                                        }
                                                    }
                                                }
                                            }
                                        }
                                    }
                                }
                            }
                        }
                    }
                }

                //                     if(row.apply_lc_state===0){
                //                         result="分管领导审核不通过"
                //                         remark=row.apply_lc_remark
                //                     }
                //                     else{
                //                         if(row.apply_dt_state===0){
                //                             result="局长审核不通过"
                //                             remark=row.apply_dt_remark
                //                         }
                //                     }
                //                 }
                //             }
                //         }
                //     }
                // }
                // else{
                //     result='等待审核'
                // }
                
                if(type===1)
                    return result
                if(type===2)
                    return remark
            }
        }
    },
    created(){
        this.GetDatalist()
        this.User= JSON.parse(window.sessionStorage.getItem('rs_login_user'))
    },  
    mounted(){
        if(this.$store.state.CompanyDataList.length===0){
            this.$store.dispatch('getCompanyDataList')
        }
        if(this.$store.state.LocationDataList.length===0){
            this.$store.dispatch('getLocationDataList')
        }
        get("route/getroutedatalist").then(res=>{
            if(res.rpStatus===10000){
                this.routeDatalist=res.list
            }
        })


    },
    methods:{
        GetDatalist(){
            get('apply/getapplydatalist',this.searchForm).then(res=>{
                if(res.rpStatus===10000){
                    this.searchForm.total=res.total
                    this.ApplyDataList=res.list


                    
                }
            })
        },
        current_change(page){
            this.searchForm.pageNo=page
            this.GetDatalist()
        },
        handleSizeChange(pageSize){
            this.searchForm.pageSize=pageSize
            this.searchForm.pageNo=1
            this.GetDatalist()
        },
        Add(){
            this.applyDialog=true
            this.title='新增申请单'
            this.applyForm.apply_id=0
            this.applyForm.apply_build_name=''
            this.applyForm.apply_building_id=0
            this.applyForm.apply_owner_unit=''
            this.applyForm.apply_build_unit=''
            this.applyForm.apply_company_id=0
            this.applyForm.apply_weight=0
            this.applyForm.apply_route_id=0
            this.applyForm.apply_sign_contract=0
            this.applyForm.apply_inout=0
            this.applyForm.apply_dump_id=0
            this.applyForm.apply_limit_day=[this.FormData(new Date(),2),'2024-12-31']
            this.applyForm.apply_limit_time=['2023-01-01 00:00','2023-01-01 00:00']
            this.applyForm.apply_route_id=0
            this.applyForm.apply_remark=''
            this.applyForm.apply_state=0
        },
        Edit(row){
            this.applyForm.apply_id=row.apply_id
            this.applyForm.apply_build_name=row.apply_build_name
            this.applyForm.apply_building_id=row.apply_building_id
            this.applyForm.apply_owner_unit=row.apply_owner_unit
            this.applyForm.apply_build_unit=row.apply_build_unit
            this.applyForm.apply_company_id=row.apply_company_id
            this.applyForm.apply_weight=row.apply_weight
            this.applyForm.apply_route_id=row.apply_route_id
            this.applyForm.apply_sign_contract=row.apply_sign_contract
            this.applyForm.apply_inout=row.apply_inout
            this.applyForm.apply_dump_id=row.apply_dump_id
            this.applyForm.apply_limit_day=[this.FormData(row.apply_limit_begin_day,2),this.FormData(row.apply_limit_end_day,2)]
            this.applyForm.apply_limit_time=[this.FormData(row.apply_limit_begin_time,2),this.FormData(row.apply_limit_end_time,2)]
            var imgUrl=this.User.user_type===3?("data:image/jpeg;base64,"+row.apply_fb_img):(this.User.user_type===4?('data:image/png;base64,'+row.apply_ps_img):'')
            this.fileList=[]
            if((this.User.user_type===3&&row.apply_fb_img!==null)||(this.User.user_type===4&&row.apply_ps_img!==null)){
                this.Hide()
                this.applyForm.imgData=imgUrl
                this.fileList.push({
                    name:'111.jpg',
                    uid:'1',
                    url:imgUrl
                })
            }
            
            this.applyDialog=true
            this.title='修改'
            this.applyForm.apply_state=this.User.user_type===3?row.apply_pd_state:(this.User.user_type===3?row.apply_fb_state:(
                this.User.user_type===4?row.apply_ps_state:(this.User.user_type===5?row.apply_pb_state:(this.User.user_type===6?row.apply_lc_state:row.apply_dt_state))))

            this.applyForm.apply_remark=this.User.user_type===2?row.apply_pd_remark:(this.User.user_type===3?row.apply_fb_remark:(
                this.User.user_type===4?row.apply_ps_remark:(this.User.user_type===5?row.apply_pb_remark:(this.User.user_type===6?row.apply_lc_remark:row.apply_dt_remark))))
        },
        Del(row){
            // this.$confirm('确定删除公司“' + row.cp_name + '”？', '提示', {
            //     confirmButtonText: '确定',
            //     cancelButtonText: '取消',
            //     type: 'warning'
            // }).then(() => {
            //     get('company/del?id=' + row.cp_id).then(res => {
            //         if (res.rpStatus === 10000) { this.$store.dispatch('getCompanyDataList') } else { this.$message.error(res.rpMsg) }
            //     })
            // }).catch(e => e)
        },
        submit(){
            this.applyForm.apply_limit_begin_day=this.applyForm.apply_limit_day[0]
            this.applyForm.apply_limit_end_day=this.applyForm.apply_limit_day[1]
            this.applyForm.apply_limit_begin_time=this.applyForm.apply_limit_time[0]
            this.applyForm.apply_limit_end_time=this.applyForm.apply_limit_time[1]

            this.$refs.applyForm.validate((valid) => {
                if (valid) {
                    post('apply/edit', this.applyForm).then(res => {
                        if (res.rpStatus === 10000) {
                            this.applyDialog=false
                            this.GetDatalist()
                        } else { this.$message.error(res.rpMsg) }
                    })
                }
            })
        },

        handleChange(file, fileList) {
			this.Hide()
            
            const self = this
            const fileReader = new FileReader()
            fileReader.readAsDataURL(file.raw)
            fileReader.onload = function (e) {
                console.log(fileReader.result)
                self.applyForm.imgData= fileReader.result
            }
		},
        Hide(){
            this.objClass.upLoadHide = true;//上传图片后置upLoadHide为真，隐藏上传框
			this.objClass.upLoadShow = false;
        },


		handleRemove(file, fileList) {
			this.objClass.upLoadShow = true;//删除图片后显示上传框
			this.objClass.upLoadHide = false;
            this.applyForm.imgData=''
		},
		handlePictureCardPreview(file) {
			this.dialogImageUrl = file.url;
			this.dialogVisible = true;
		},
    }
}
</script>

<style>
.content{
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    height: 100%;
}
.search{
    display: flex;
    flex-direction: row;
    justify-self: start;
    align-items: center;
}
.item{
  display: flex;
  flex-direction: row;
  align-items: center;
  font-size: 12px;
}
.item label{
  margin-right: 8px;
  width: 80px;
  text-align: right;
}
.el-table{
    margin-top: 10px;
}
.el-link{
    margin-right: 10px;
    font-size: 12px;
}
.span{
    display: flex;
    flex-direction: column;
    align-items: center;
}
.a{
    font-weight: 700;
    font-size: 17px;
    margin-top:10px;
}
.b{
    padding-top: 35px;
}



.upLoadShow .el-upload {
		width: 10rem !important;
		height: 10rem !important;
		line-height: 10rem !important;
	}

        /*当upLoadHide为true时，启用如下样式，即缩略图的样式，若为false则不启用该样式*/
	.upLoadHide .el-upload-list--picture-card .el-upload-list__item {
		width: 10rem !important;
		height: 10rem !important;
		line-height: 10rem !important;
	}
        /*当upLoadHide为true时，启用如下样式，即上传框的样式，若为false则不启用该样式*/
	.upLoadHide .el-upload {
		display: none;
	}



</style>